import axios from "axios";
import './single_news.css';
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { News } from "../components/interfaces/News";
import ReactMarkdown from 'react-markdown';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";


const SingleNews = () => {
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL!;
    const FACEBOOK_LINK = 'https://www.facebook.com/polski.wiatrak';
    const YOUTUBE_LINK = 'https://www.youtube.com/@PolskiWiatrak';

    const [news, setNews] = useState<News>({
        author: '',
        category: {
            id: 0,
            name: ''
        },
        date: new Date(),
        id: 0,
        text: '',
        title: ''
    });

    if(!id) {
        navigate('/');
    }

    const getById = useCallback(async (id: number): Promise<News | void>=> {
        try {
            const response = await axios.get(API_URL + '/news/' + id);
            const body = response.data;
            return {
                id: body.id,
                title: body.title,
                text: body.text,
                author: body.author.login,
                date: new Date(body.added),
                category: body.newsCategory
            };
        } catch(err) {
            console.log(err);
            alert('Something went wrong');
            navigate('/');
        };
    }, [API_URL, navigate]);

    const translateMonth = (month: number): string => {
        switch(month) {
            case 1: return 'Stycznia';
            case 2: return 'Lutego';
            case 3: return 'Marca';
            case 4: return 'Kwietnia';
            case 5: return 'Maja';
            case 6: return 'Czerwca';
            case 7: return 'Lipca';
            case 8: return 'Sierpnia';
            case 9: return 'Września';
            case 10: return 'Października';
            case 11: return 'Listopada';
            case 12: return 'Grudnia';
            default: return '';
        }
    }

    useEffect(() => {
        getById(parseInt(id!))
        .then(news => {
            console.log(news);
            if(news) {
                setNews(news);
            }
        });
    }, [getById, id]);


    return (
        <article className="w-[95%] md:w-[60%]">
            <Helmet>
                <title>{news.title}</title>
                <meta property="og:title" content={news.title} />
            </Helmet>
            <div className="bg-[#F5F5F5] p-16 rounded-lg flex justify-center">
                <div>
                    <h2 className="text-4xl font-bold">
                        {news.title}
                    </h2>
                    <div className="flex justify-between items-start">
                        <img src="/img/img_lines_virtical_yellow_600.svg" alt="yellow-line" className="ml-[-1rem]"/>
                    </div>
                </div>
                {/* <img src="/img/img_highlight_effec.svg" alt="black lines" className="mt-[-20px]"/> */}

            </div>
            <div className="flex flex-col gap-4 text-justify text-lg mb-8">
                <ReactMarkdown>
                    {news.text}
                </ReactMarkdown>
            </div>
            <div className="bg-[#F5F5F5] rounded-lg flex my-16 items-center justify-around py-3">
                <div className="flex gap-1 items-center">
                    <FontAwesomeIcon icon={faUser} className="h-3 mb-1" />
                    Autor: {news.author === 'Kay' ? 'Tomasz' : news.author}
                </div>
                <div className="flex gap-1">
                        <img src="/img/calendar.svg" alt="calendar" />
                        {`${('0' + news.date.getDay()).slice(-2)} ${translateMonth(news.date.getMonth())}, ${news.date.getFullYear()} r.`}
                    </div>
                {news.category ? 
                <div className="flex gap-1">
                    <img src="/img/category.svg" alt="category" />
                    Kategoria: {news.category.name}
                </div>
                : <></>}
            </div>
            <div className="bg-[#F5F5F5] p-16 rounded-lg flex flex-col items-center justify-center gap-10">
                <div className="flex flex-col gap-8 items-center text-center">
                    <h2 className="text-4xl font-bold">
                        ŚCIĄGNIJ NASZĄ APLIKACJE
                    </h2>
                    <h3 className="text-xl">
                        Ściagnij nasza darmową aplikację dostępną na Google Play (iOS dostępny w późniejszym terminie).<br/> Informacje w zasięgu ręki.
                    </h3>
                </div>
                <div className="flex justify-center gap-12">
                    <a href="https://polski-wiatrak.pl/apka">
                        <img src="/img/google_play.svg" alt="google play" />
                    </a>
                    <img src="/img/app_store.svg" alt="app store" className="cursor-not-allowed"/>
                </div>
            </div>
            <div className="bg-[#F5F5F5] p-16 rounded-lg flex flex-col items-center justify-center gap-20 mt-20">
                <div className="flex flex-col gap-8 items-center text-center">
                    <h2 className="text-4xl font-bold">
                        NASZE SOCJALE
                    </h2>
                </div>
                <div className="flex justify-center gap-12">
                    <div className="flex gap-12">
                        <a href={FACEBOOK_LINK}>
                            <FontAwesomeIcon icon={"facebook-f"} />
                            {/* <img className="h-[40px] w-[50px] ml-1" src="/img/facebook.png" alt="Facebook" />     */}
                        </a>
                        <a href={YOUTUBE_LINK}>
                            <img className="h-[40px] w-[40px]" src="/img/youtube.png" alt="Twitter" />
                        </a>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default SingleNews;