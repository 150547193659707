import Box from "./Box";
import './footer.css';

const Footer = () => {
    const SUPPORT_LINK = 'https://paypal.me/k4yllie';
    const emails: string[] = [
        'support@polski-wiatrak.pl',
        'tomasz@polski-wiatrak.pl',
        'dev@polski-wiatrak.pl',
    ];
    const FACEBOOK_LINK = 'https://www.facebook.com/polski.wiatrak';
    const YOUTUBE_LINK = 'https://www.youtube.com/@PolskiWiatrak';

    return (
        <footer
            className='grid grid-cols-1 md:grid-cols-6 gap-8 mt-28 px-14 py-32 bg-[#2E3646]'
        >
            <Box header="Polski Wiatrak">
                <p>Aplikacja społecznościowa, która ma na celu pomóc rodakom w Kraju Niderlandów.</p>
            </Box>
            <Box header="Wesprzyj projekt">
                <ul className="list-disc">
                    <li key='support_description'>Aby nas wesprzeć możesz kliknąć link i zostawić datek.</li>
                    <li key='support_link'><a href={SUPPORT_LINK}>Kliknij tutaj</a></li>
                </ul>
            </Box>
            <Box header="Skontaktuj się">
                {emails.map(e => <a href={'mailto:' + e}>{e}</a>)}
            </Box>
            <div className="col-span-2 hidden md:block"></div>
            <Box header="Social Media">
                <div className="flex gap-12">
                    <a href={FACEBOOK_LINK}>
                        <img className="h-[40px] w-[50px] ml-1" src="/img/facebook.png" alt="Facebook" />    
                    </a>
                    <a href={YOUTUBE_LINK}>
                        <img className="h-[40px] w-[40px]" src="/img/youtube.png" alt="Twitter" />
                    </a>
                </div>
            </Box>
        </footer>
    );
}

export default Footer;