import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './components/Layout';
import Home from './page/Home';
import NoPage from './page/NoPage';
import SingleNews from './page/SingleNews';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                {/* <Route path="/news" element={<News />} /> */}
                <Route path="/news/:id" element={<SingleNews />} />
                <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
