// import { Link } from "react-router-dom";

const Header = () => {
    const FACEBOOK_LINK = 'https://www.facebook.com/polski.wiatrak';
    const YOUTUBE_LINK = 'https://www.youtube.com/@PolskiWiatrak';
    return (
        <header className="flex flex-row justify-evenly mt-2 py-6 content-center">
            <div className="flex flex-row text-gray-800 justify-evenly flex-1 content-center flex-wrap">
                {/* <Link to={'/'}>GŁÓWNA</Link>
                <Link to={'/o-aplikacji'}>O APLIKACJI</Link>
                <Link to={'/social-media'}>SOCIAL MEDIA</Link>
                <Link to={'/kontakt'}>KONTAKT</Link> */}
            </div>
            <img src="/img/img_header_logo.png" alt="Logo" className="h-[78px] w-[206px] object-contain flex-auto" />
            <div className="flex flex-row justify-evenly flex-1 content-center flex-wrap">
                <a href={YOUTUBE_LINK}>
                    <img className="h-[40px] w-[40px]" src="/img/youtube_yellow.png" alt="Twitter" />
                </a>
                <a href={FACEBOOK_LINK}>
                    <img className="h-[40px] w-[40px] ml-3" src="/img/facebook_yellow.svg" alt="Facebook" />    
                </a>
            </div>
        </header>
    );
}

export default Header;