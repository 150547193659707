import { ReactNode } from "react";

const Box = ({header, children}: {header: string, children: ReactNode}) => {
    return (
        <div className="flex flex-col text-white">
            <h4 className="font-bold text-2xl">{header}</h4>
            <div className="flex flex-col mt-4 leading-8">
                {children}
            </div>
        </div>
    );
}

export default Box;