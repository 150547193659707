import Card from "../components/home/Card";

const Home = () => {
    return (
        <div className="flex flex-col gap-40 w-full items-center justify-center">
            <section className="text-center gap-8 grid place-items-center mt-36">
                <div className="grid gap-0 line-clamp-0">
                    <div className="flex flex-row justify-evenly">
                        <img className="mt-[-50px]" src="img/img_arrow_filled_he.svg" alt="Arrow"/>
                        <h1 className="text-black-900 text-[74px] font-bold">
                            FANPAGE
                        </h1>
                        <img className="mt-[-180px]" src="img/img_highlight_effec.svg" alt="Lines"/>
                    </div>
                    <h1 className="text-[#FED636] text-[74px] font-bold">INFORMACJE AUDIOWIZUALNE</h1>
                </div>
                <p className="text-3xl md:mx-64">
                Masz pytania na które nie ma odpowiedzi w naszej aplikacji? Nie martw się! <br/> Nasza mała (ale wciąż
                    rosnąca) społeczność chętnie pomoże. <br/> Zerknij na naszą grupę facebookową o tej samej nazwie.
                </p>
                <img className="m-16" src="img/news_image.svg" alt="news-image" />
            </section>
            <section className="flex flex-col items-center justify-center text-center">
                <div className="border-2 border-black rounded-2xl items-center justify-center flex flex-col p-40">
                    <h2 className="text-[#FED636] font-bold text-4xl">KIM JESTEŚMY</h2>
                    <img src="img/img_lines_virtical.svg" alt="yellow-line"/>
                    <p className="text-3xl mt-4">
                    Nad aplikacją współpracują dwie osoby. <br/>
                    Jedna z nich żyje od ponad dekady w kraju tulipanów i wiatraków. Podzieli się <br/>
                    swoim doświadczeniem, a także subiektywną opinią oraz uwagami - tak, aby <br/>
                    uczynić Wasz start łatwiejszym i przyjemniejszym w Niderlandach. <br/>
                    Druga -Webmaster pasjonat, który sprawi, że strona będzie przyjazna, <br/>
                    a aplikacja intuicyjnie prosta. <br/>
                    Z czasem będą przybywać dodatkowe funkcje oraz ułatwienia.</p>
                </div>
            </section>
            <section className="bg-[#2E3646] py-[150px] flex flex-col text-center justify-center items-center w-full">
                <div>
                    <h2 className="text-white font-bold text-[40px]">
                        TWÓRCY
                    </h2>
                    <img src="img/img_lines_virtical_yellow_600.svg" alt="yellow-line"/>
                </div>
                <div className="flex flex-row gap-8 mt-10">
                    <Card author="kay" description="Właściciel" email="kay@polski-wiatrak.pl" />
                    <Card author="halfix" description="Programista" email="dev@polski-wiatrak.pl"/>
                </div>
            </section>
            <section className="flex flex-col justify-center text-center items-center gap-8 text-[#2C3958]">
                <div>
                    <h4 className="font-bold text-[40px]">KOD QR</h4>
                    <img src="img/img_lines_virtical_yellow_600.svg" alt="yellow-line"/>
                </div>
                <img src="img/kod_qr.png" alt="Kod QR aplikacji mobilnej" height='363px' width='363px'/>
                <h4 className="mt-16 font-bold text-[40px]">Polski Wiatrak</h4>
                <p className="text-3xl px-32">Zeskanuj kod swoim telefonem, aby szybko znaleźć <br/> aplikację w Google Store!</p>
            </section>
        </div>
    );
}

export default Home;